import PaginationRequest from "@/models/general/PaginationRequest";
import PaginationResponse from "@/models/general/PaginationResponse";
import WerehouseInputRow from "@/models/weewhouse/WerehouseInputRow";
import FiltersInputs from "@/models/weewhouse/filters/FiltersInputs";
import { AxiosResponse } from "axios";
import GeneralService from "../interfaces/GeneralService";
import TableInputsService from "./interfaces/TableInputsService";
import MessageService from "../interfaces/MessageService";
import SwalMessageService from "../SwalMessageService";
import ApiService from "../ApiService";
import { getMessageError } from "@/core/helpers/messageFromPulmeros";

export default class WerehouseInputsService implements GeneralService, TableInputsService {

    apiController = "Inputs";
    messageService: MessageService = new SwalMessageService();
    
    
    async searchTableInputs(pagination: PaginationRequest, filters: FiltersInputs): Promise<AxiosResponse<PaginationResponse<WerehouseInputRow>>> {
        let customerIdsFilters = '';
        if (filters.customerIds) { 
            filters.customerIds.every(customerId => {
                customerIdsFilters += (customerId && customerId != '' ? `&Criteria.SupplierIds=${customerId}` : '')
                return true;
            })
        }
       
        return ApiService.get<PaginationResponse<WerehouseInputRow>>(this.apiController, 
            `GetPagedList?`//puede cambiar esta ruta
            + `CurrentPage=${pagination.currentPage}`
            + `&PageSize=${pagination.pageSize}`
            + (filters.folio && filters.folio != '' ? `&Criteria.Folio=${filters.folio}` : '')
            + (filters.relatedFolio && filters.relatedFolio != '' ? `&Criteria.RelatedFolio=${filters.relatedFolio}` : '')
            + (customerIdsFilters && customerIdsFilters != '' ? customerIdsFilters : '')
            + (filters.movementTypeId && filters.movementTypeId != '' ? `&Criteria.MovementTypeId=${filters.movementTypeId}` : '')
            + (filters.startDate && filters.startDate != '' ? `&Criteria.InitialDate=${filters.startDate}` : '')
            + (filters.endDate && filters.endDate != '' ? `&Criteria.FinalDate=${filters.endDate}` : '')
            + (filters.status && filters.status != '' ? `&Criteria.status=${filters.status}` : '')
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    } 

    async searchInputInfo(id: string): Promise<AxiosResponse<WerehouseInputRow>> {
        return ApiService.get(`${this.apiController}/${id}`)
        .then(res => {
            return res;
        }).catch(reject => {
            getMessageError(reject, this.messageService);
                return { data: null} as AxiosResponse;
        });
     }

}