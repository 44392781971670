
import { computed, defineComponent, onBeforeMount, onBeforeUnmount, onMounted, reactive, ref } from 'vue';
import ModuleMaster from "../../../components/c-module-master/module-master.vue";
import CrudActions from "../../../components/c-module-master/crud-actions.vue";
import Datatable from "../../../components/c-datatable/Datatable.vue";
import Datarow from "../../../components/c-datatable/Datarow.vue";
import ApiService from "@/core/services/ApiService";
import { formatToDatatable } from "../../../common/formaterToDatatable";
import InputEditForm from "./InputEditForm.vue";
import SwalMessageService from "@/core/services/SwalMessageService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import { getMessageError } from '@/core/helpers/messageFromPulmeros';
import { useRouter } from "vue-router";
import AditionalActions from "../common/aditional-actions.vue";
import { useStore } from "vuex";
import  PDFViewer from "@/views/cashregister/components/PDFViewer.vue";
import { filter } from "../common/commonUtilities";
import * as Validations from "yup";
import { InputTypes, getOptions } from '@/core/enums/inputs-types';
import _ from "lodash";
import tableModule from "@/store/modules/werehouse/modules/inputs-table"
import FiltersInputs from '@/models/weewhouse/filters/FiltersInputs';
import InputText from '@/components/forms/InputText.vue'
import Select from '@/components/forms/Select.vue'
import SelectFilterRemote from '@/components/forms/SelectFilterRemote.vue'
import DatePickerRange from '@/components/forms/DatePickerRange.vue'
import { Form } from 'vee-validate'
import { toggleCollapse } from '@/core/helpers/dom';

export default defineComponent({
    components: {
        ModuleMaster,
        Datatable,
        Datarow,
        CrudActions,
        InputEditForm,
        AditionalActions,
        PDFViewer
         , InputText
        , Form
        , Select
        , SelectFilterRemote
        , DatePickerRange
    },
    setup() {
        //VARIABLES Y CONSTANTES
        const messageService = new SwalMessageService();
        const router = useRouter();
        let records = ref([]);
        let modeForm = ref("N");
        let currentPage = ref(1);
        let cantRecordsPage = ref(20);
        let totalItems = ref(0);
        const btnForm = ref();
        const btnDetails = ref();
        const currentItem = ref();
        let textSearch = ref("");
        const openPDF = ref();
        let pdfMovement = ref("");
        let form = ref();
        const idCollapse = ref('collapse-inputs-filters');

        const controller = "inputs";
        let titleEditForm = ref("Nuevo Movimiento de Entrada");
        const labels = reactive({
            placeHolderToFieldSearch: "Buscar",
            titleNewCrudAction: "Nuevo Registro",
        });
        const arrayTitleActions = [
            { action: "N", title: "Nuevo Movimiento de Entrada" },
            { action: "M", title: "Modificar Movimiento de Entrada" },
            { action: "V", title: "Visualizar Movimiento de Entrada" }
        ];
        
        const configTable = reactive([
            { PropName: "folio", HeadLabel: "Folio", Type: "text", VisibleInGrid: true },
            { PropName: "relatedFolio", HeadLabel: "Folio O.C.", Type: "text", VisibleInGrid: true },
            { PropName: "supplierName", HeadLabel: "Proveedor", Type: "text", VisibleInGrid: true },
            { PropName: "createdAt", HeadLabel: "Fecha Movimiento", Type: "fullDate", VisibleInGrid: true },
            { PropName: "total", HeadLabel: "Total", Type: "moneda", VisibleInGrid: true },
            { PropName: "movementTypeName", HeadLabel: "Tipo", Type: "text", VisibleInGrid: true }
        ]);
        const formFilters = ref();
        const btnFilters = ref();
        const validationSchemaFilters = Validations.object().shape({
            folio: Validations.string().default('')
            , relatedFolio: Validations.string().default('')
            , customerFolio: Validations.string().default('')
            , customerIds: Validations.array().default([])
            , startDate: Validations.date().default(undefined).optional()
            , endDate: Validations.date().default(undefined).optional()
            , movementTypeId: Validations.string().default('')//solo acepta enumerador de recepciones
            , status: Validations.string().default('').optional()
        })
        const store = useStore();
        let intervalId;
        
        const optionsStatus = [
            { id: 0, name: "Cptura Inicial"},
            { id: 1, name: "Finalizadas"},
            { id: 2, name: "Aprobadas"},
            { id: 3, name: "Canceladas"},
            { id: 5, name: "Precomprometidas"},
            { id: 4, name: "Comprometidas"}            
        ]

        //HOOKS
        onBeforeMount(async() => {
            await getPagedItems();
        });

        onMounted(() => {
            setCurrentPageBreadcrumbs("Entradas", ["Módulos", "Almacén"]);
            tableModule.UPDATE_FILTERS({});
            tableModule.getComboMovementType('');
            setTimeout(() => {
                formFilters.value?.setValues({
                    ...tableModule.filters
                })
            }, 800);

            intervalId = setInterval(tableModule.SEARCH_TABLE_DATA, 120000);
        });

        onBeforeUnmount(() => {
            clearInterval(intervalId);
        });

        //VARIABLES COMPUTADAS
        const headers = computed(() => {
            return configTable.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel);
        });

        const renderRows = computed(() => {
            return formatToDatatable(tableModule.getRecordsTable, configTable, "status");
        });

        const currentArticle = computed(() => {
            return tableModule.getRowSelected;
        });

        const titleEditFormAction = computed(() => {
            return titleEditForm.value;
        });

        const totalRecords = computed(() => tableModule.totalRecords);

        const selectedPage = computed(() => tableModule.pagination.currentPage);

        const modeEditForm = computed(() => {
            return modeForm.value;
        });

        const getItemsPerPage = computed(() => tableModule.pagination.pageSize);
        const customerOptions = computed(() => tableModule.selects.customerOptions)
        const optionsTypes = computed(() => tableModule.selects.movementTypeOptions);
        /**PERMISOS */
        const canCreate = computed(() =>  store.getters["canCreateInputs"]);
        const canApprovalShipManager = computed(() => store.getters["canManagerShipApproval"]);
        const canApprovalWarehouseManager = computed(() => store.getters["canManagerWarehouseApproval"]);
        
        //FUNCIONES
        const searchCustomers = _.debounce((filterText: string) => {
            tableModule.getComboCustomer(filterText);
        }, 550, { leading: false, trailing: true });

        const cleanFilters = () => {
            formFilters.value?.resetForm();
            resetFilterDateInitial();
            tableModule.RESET_FILTERS();
            btnFilters.value?.click();
        }
           /**
         * Resetea la busqeuda inicial por fecha de los filtros
         */
        const resetFilterDateInitial = () => {
            const endDate: Date = new Date();
            //const endDate: Date = addDays(startDate, 15);
            const startDate = new Date(endDate);
            endDate.setDate(endDate.getDate() - 15);

            /* tableModule.UPDATE_FILTERS({
                 startDate: startDate.toISOString()
                 , endDate: endDate.toISOString()
                 , filterStatus: undefined
                 , folio: ''
                 , supplierFolio: ''
                 , supplierIds: []
             });*/

            formFilters.value?.setFieldValue('startDate', startDate.toISOString())
            formFilters.value?.setFieldValue('endDate', endDate.toISOString())
        }

        const handleChangePage = async(page: number) => {
            tableModule.CHANGE_PAGE({
                currentPage: page
            })
        }

        const handleCrudAction = async(action, itemID) => {
            modeForm.value = action;
            if(["N", "V", "M"].some(a => a === action)) {
                titleEditForm.value = arrayTitleActions.find(ac => ac.action == action)?.title ?? '';
                if(action === "M" || action === "V") {
                    //await getArticleById(itemID);
                    tableModule.searchInfoInputSelected(itemID)
                } 
                
                if(btnForm.value) {
                    form.value.initDefaults();
                    btnForm.value.click();
                }
            } else if(action === "E") {
                await tableModule.searchInfoInputSelected(itemID)
                const item = tableModule.getRowSelected; 
                if(item) {
                    Swal.fire({
                        title: `Está a punto de ${ item.status ? "desactivar" : "activar"} el registro. ¿Desea continuar?`,
                        icon: "warning",
                        showDenyButton: false,
                        showCancelButton: true,
                        confirmButtonText: 'Sí',
                        cancelButtonText: 'No',
                        denyButtonText: 'No',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                //changeStatusItem(item);//TODO
                            }
                        }
                    );
                    
                }  
            }
        }

        const changeStatusItem = async(item) => {
           /* let activate = !item.status;
            let url = `products/${item.id}/${activate ? 'activate' : 'deactivate'}`;
            await ApiService.put(url).then(res => {
                item.status = activate;
                handleItemSaved(item);
                messageService.success(`El registro se ${activate ? 'activó' : 'desactivó'} exitosamente.`);          
            }).catch(reject => {
                getMessageError(reject, messageService);
            });*/
        }

        const searchItems = async(text) => {
            currentPage.value = 1;
            textSearch.value = text;
            await getPagedItems();
        }

        const getPagedItems = async(criteria = "Criteria.Active=true") => {
           /* let url = `${controller}/GetPagedList?currentPage=${currentPage.value}&PageSize=${cantRecordsPage.value}&${criteria}`;
            ApiService.query(url).then(res => {
                totalItems.value = res.data.totalRecords;
                records.value = res.data.data;
            }).catch(reject => {
                getMessageError(reject, messageService);
            });*/
        }

        const getArticleById = async(id) => {
            ApiService.get(`${controller}/${id}`).then(res => {
                currentItem.value = res.data;
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const handleItemSaved = (item) => {
            switch(modeForm.value) {
                case "M"://por el momento solo buscamos la tabal de nuevo
                case "E"://por el momento solo buscamos la tabal de nuevo
                case "N":
                    if(selectedPage.value > 1) {
                        tableModule.CHANGE_PAGE({ currentPage: 1 });
                    } else {
                        tableModule.SEARCH_TABLE_DATA();               
                    }
                    break;
                /*case "M":
                    refreshSelectedItem(item.Id, updateRowItem);
                    break;
                case "E":
                     updateStatusRow(item.Id, item.status);
                    break;*/
            }
        }

        const goToPage = async (page) => {
            currentPage.value = page;
            await getPagedItems();
        }

        const updateRowItem = (item) => {
            /*let itemRow = records.value.find(ir => ir.id === item.id);
            if(itemRow) {
                itemRow.warehouseName = item.warehouseName;
                itemRow.userName = item.userName;
                itemRow.invoiceFolio = item.invoiceFolio;
                itemRow.note = item.note;
                itemRow.liquidationFolio = item.liquidationFolio;
                itemRow.movementStatus = item.movementStatus;
            }*/
        }

        const updateStatusRow = (idRow, status) => {
            /*let itemRow = records.value.find(ir => ir.id === idRow);
            if(itemRow) {
                itemRow.status = status;
            }*/
        }

        const handleAditionalActionClick = (action, movementeId) => {
            let movement = getCurrentRenderMovement(movementeId);
            tableModule.SET_SELECT_ROW(movement);
            switch(action) {
                case "DET":
                    goToDetails(movementeId);
                    break;
                case "ASM":
                    approvalMovement(movementeId, "approveByTheShipManager");
                    break;
                case "AWM":
                    approvalMovement(movementeId, "approveByTheWarehouseManager");
                    break;
                case "CI":
                    approvalMovement(movementeId, "Initial", controller, "Está a punto de reabrir la captura del movimiento de entrada.");
                    break;
                case "CF":
                    approvalMovement(movementeId, "EndCapture", controller, "Está a punto de finalizar la captura del movimiento de entrada.");
                    break;
                case "DM":
                    getPurchaseOrderPDF(movementeId);
                    break;
            }
        }

        const goToDetails = (movementeId) => {
            ApiService.get(`${controller}/${movementeId}`).then(res => {
                let dataProps = `inputId:${movementeId}|canEdit:${canEdit(movementeId)}|movementFolio:${res.data.folio}|requireCI:${canBackToCI(movementeId)}|requireCF:${!isEndedCapture(movementeId)}|requireAWM:${canApprovalWarehouseManager.value && showApprovalWarehouseManagerOption(movementeId)}`;
                if(res && res.status == 200){
                    router.push({
                        name: '/warehouse/input/:inputId/details',
                        params: {
                            inputId: btoa(dataProps),
                          //  canEdit: canEdit(movementeId),
                            movementFolio: res.data.folio
                        }
                    });
                }    
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const approvalMovement = (movementeId, approvalType, controllerAp = "inputs", messageTitle = "Está a punto de autorizar el movimiento.") => {
            Swal.fire({
                title: `${messageTitle} ¿Desea continuar?`,
                icon: "warning",
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: 'Sí',
                cancelButtonText: 'No',
                denyButtonText: 'No',
                }).then((result) => {
                    if (result.isConfirmed) {
                        ApiService.put(`${controllerAp}/${movementeId}/${approvalType}`, {}).then(res => {
                            if(res.status == 204){
                                refreshSelectedItem(movementeId, updateApprobalStatusCol, approvalType);
                                // if(approvalType == "approveByTheWarehouseManager") {
                                //     getPurchaseOrderPDF(movementeId);
                                // }                          
                            }     
                        }).catch(reject => {
                            getMessageError(reject, messageService);
                        }); 
                    }
                }
            );
        }

        const refreshSelectedItem = (itemId, callbackFunction, approvalType = "") => {
            ApiService.get(`${controller}/${itemId}`).then(res => {
                if(res && res.status == 200 && callbackFunction){
                    callbackFunction(res.data, approvalType);
                }    
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const approvalMessages = [
            { type: "EndCapture", message: "La captura del movimiento de entrada ha finalizado de menera correcta." },
            { type: "Initial", message: "La captura del movimiento de entrada ha sido reabierta de menera correcta." },
            { type: "default", message: "El movimiento de entrada fue autorizado de manera correcta." }
        ];

        const getApprovalMessage = (approvalType) => {
            let approvalMsg = approvalMessages.find(am => am.type == approvalType);
            if(approvalMsg == null) {
               approvalMsg = approvalMessages.find(am => am.type == "default");
            }
            return approvalMsg ? approvalMsg.message : "El cambio de etapa se realizó de manera correcta.";
        }

        const updateApprobalStatusCol = (item, approvalType) => {
           let itemRow = tableModule.records.find(ir => ir.id === item.id);
            let message = getApprovalMessage(approvalType);
            if(itemRow) {
                if(approvalType == "EndCapture") {
                    itemRow.status = 1;
                }
                if(approvalType == "Initial") {
                    itemRow.status = 0;
                }
                messageService.success(message);
            }
        }

        const approvalNames = [
            { id: "ASM", name: "shipManagerApproval", id2: "approveByTheShipManager" },
            { id: "AWM", name: "whereHouseManagerApproval", id2: "approveByTheWarehouseManager" }
        ];

        const showApprovalWarehouseManagerOption = (movementId) => {
            let movement = getCurrentRenderMovement(movementId);
            return movement ? movement['eMovementStatus'] == 1 && !movement['whereHouseManagerApproval']: false;
        }

        const canBackToCI = (movementId) => {
            let movement = getCurrentRenderMovement(movementId);
            return  movement ? movement.status == 1 : false;
        }

        const isEndedCapture = (movementId) => {
            let movement = getCurrentRenderMovement(movementId);
            return (movement?.status ?? -1) == 1;
        }

        const canEdit = (movementId) => {
            let movement = getCurrentRenderMovement(movementId);
            return movement ? /*movement.eMovementStatus == 0
                ||*/ (store.getters["canEditCaptureEndedMovement"]
                    /*&& !movement.whereHouseManagerApproval*/) : false;
        }

        const getCurrentRenderMovement = (movementId) => {
            return tableModule.getRecordsTable.find((r) => r.id == movementId);
        }

        const getPurchaseOrderPDF = (orderId) => {
            ApiService.post(`movements/ReportMovementPdf?MovementId=${orderId}` ,{})
            .then( resp => {
                if(resp.status == 200 && openPDF.value) {
                    pdfMovement.value = `data:application/pdf;base64,${resp.data}`;
                    openPDF.value.click();
                }
            }).catch(error => {
                getMessageError(error, messageService);
            });
        }

        const canDownloadPDFMovement = (movementId) => {
            let movement = getCurrentRenderMovement(movementId);
            return movement ? movement['whereHouseManagerApproval'] : false;
        }

        const applyFilters = async (filters) => {
            let criteria = filter.criteria(filters);
            if(filters.NumberItems) {
                cantRecordsPage.value = filters.NumberItems;
            }
            await getPagedItems(criteria);
        }

        const searchInputs = (filters: FiltersInputs) => { 
            tableModule.UPDATE_FILTERS(filters)
            toggleCollapse("#" + idCollapse.value);
        }
        const print = (itemId: string) => { 
            ApiService.get(`${controller}/GetReceipt/${itemId}`)
            .then( resp => {
                if(resp.status == 200 && openPDF.value) {
                    pdfMovement.value = `data:application/pdf;base64,${resp.data}`;
                    openPDF.value.click();
                }
            }).catch(error => {
                getMessageError(error, messageService);
            });
        }

        return {
            //variables y constantes
            labels,
            btnForm,
            form,
            btnDetails,
            openPDF,
            pdfMovement,
            idCollapse,
            validationSchemaFilters,
            customerOptions,
            optionsTypes,
            formFilters,
            btnFilters,
            EndCapture: InputTypes.EndCapture,
            optionsStatus,
            //varaibles computadas
            headers,
            renderRows,
            titleEditFormAction,
            totalRecords,
            selectedPage,
            currentArticle,
            modeEditForm,
            canApprovalShipManager,
            canApprovalWarehouseManager,
            canCreate,
            getItemsPerPage,

            //funciones
            handleChangePage,
            handleCrudAction,
            handleAditionalActionClick,
            handleItemSaved,
            searchItems,
            showApprovalWarehouseManagerOption,
            isEndedCapture,
            canBackToCI,
            canEdit,
            canDownloadPDFMovement,
            applyFilters
            , searchInputs
            , searchCustomers
            , cleanFilters
            , print
        }
        
    },
})
